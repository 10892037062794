.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.qr {
  width: 100%;
  height: auto;
  max-width: 500px;
}

/* Missions CSS */

.missionPrimaryTitle {
  color: #FFFFFF;
  font-family: "Urbanist", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #FF4F00;
  padding: 0px 8px;
  border-radius: 8px;
  margin-left: 8px;
}

.missionSecondaryTitle {
  color: #FF4F00;
  font-family: "Urbanist", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  margin-left: 8px;
}

.normalText {
  color: #000;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputTitle {
  color: #111926;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inputSubtitle {
  color: #858991;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}

.inputBorders {
  border: solid 1px #D9D9D9;
  border-radius: 8px;
  padding: 8px 16px;
}

.missionButton {
  display: flex;
  width: 226px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px !important;
  background: #111926;
  color: white;
}

.mt-8 {
  margin-top: 80px;
}

.MuiInput-underline:before {
	border-bottom: none;
}

.MuiInput-underline:after {
	border-bottom: none;
}

input#outlined-text-input {
  border: 1px solid #000000de;
  border-radius: 8px;
padding: 8px 16px;
}

.custom-select-mission {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23767676' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41zM12 2h-1v10h1V2z'/%3E%3C/svg%3E") no-repeat right 12px center/16px auto;
  padding-right: 32px;
}

.custom-select-mission option::after {
  content: attr(data-content); /* Utiliza el atributo data-content para mostrar el contenido adicional */
  display: block;
  font-style: italic;
  color: gray;
}

div.nav-menu-item > a.prepend-icon.nav-menu-link.active {
  background-color: #000000;
  color: white;
}

div.nav-section > .MuiList-root.nav-header.MuiList-padding {
  font-weight: 700;
  margin-top: 16px;
}

a.prepend-icon.nav-menu-link > svg {
  stroke: #212121;
  stroke-width: 1;
}

a.prepend-icon.nav-menu-link.active > svg {
  fill: white;
  stroke: white;
  stroke-width: 1;
}